import './assets/oplanet.css'

import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import { i18nextPlugin } from './localization'
import { createHead } from '@unhead/vue'
// import Vue3EasyDataTable from 'vue3-easy-data-table'
// app.component('EasyDataTable', Vue3EasyDataTable)

const app = createApp(App)
const head = createHead()

app.use(head)
app.use(i18nextPlugin)
app.use(router)

app.mount('#app')
