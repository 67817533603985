import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LocalePicker from '@/components/common/LocalePicker.vue'
import ReportView from '@/views/ReportView.vue'
import ReportsView from '@/views/ReportsView.vue'
import ContributorsView from '@/views/ContributorsView.vue'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home-default',
            components: { default: HomeView, LocalePicker }
        },
        {
            path: '/:locale',
            name: 'home',
            components: { default: HomeView, LocalePicker }
        },
        {
            path: '/:locale/reports',
            name: 'reports',
            components: { default: ReportsView, LocalePicker }
        },
        {
            path: '/:locale/report/:id',
            name: 'report',
            components: { default: ReportView, LocalePicker }
        },
        {
            path: '/:locale/contributors',
            name: 'contributors',
            components: { default: ContributorsView, LocalePicker }
        }
    ]
})

export default router
