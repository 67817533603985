import { DefaultOptions, type Options } from "./options";

const skins: {[key: string]: Options} = {
    "-": DefaultOptions(),
    "en - default": DefaultOptions(),
    "nl - default": Object.assign(DefaultOptions(), {
        title: "[TOT: {td}] {att} vs. {def} (A: {tda}, V: {tdd})"
    }),
    "pl - default": Object.assign(DefaultOptions(), {
        hidetime: false,
        title: "{att} vs. {def} ~ {hmc}"
    }),
    "pl - Seek & Destroy": Object.assign(DefaultOptions(), {
        gotdebris: 2,
        hidetime: false,
        title: "{att} vs. {def} ~ {hmc}"
    }),
    "es - default": Object.assign(DefaultOptions(), {
        title: "{att} vs. {def} [TOT: {td}]",
        layout: [
            { id: "combatstart", show: false },
            { id: "combatend", show: true },
            { id: "stats", show: true },
            { id: "moon", show: true },
            { id: "harvest", show: true },
            { id: "missiles", show: true },
            { id: "summary", show: true },
        ]
    }),
    "br - default": Object.assign(DefaultOptions(), {
        title: "Pt: {td} ::: {att} vs. {def} ::: Lt: {tpa}"
    }),
    "da - default": Object.assign(DefaultOptions(), {
        combattech: true
    })

}

export default skins