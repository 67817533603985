<script setup lang="ts">
import { computed, ref } from 'vue';
import ModalComponent from './ModalComponent.vue';

const show = ref(true)

type Announcement = {
    id: string,
    title?: string,
    body?: string,
}

const announcement: Announcement = {
    id: "migration-v5"
}

const seen = computed({
    get: () => {
        show.value = localStorage.getItem(`seen-${announcement?.id}`) != 'true'
        return show.value
    },
    set: (val) => {
        show.value = val
        localStorage.setItem(`seen-${announcement?.id}`, 'true')
    }
})

</script>

<template>
    <ModalComponent v-model="seen" :center="true" :width="600">
        <h1>🎉 Welcome to OGotcha v5 🎉</h1>
        <p style="white-space: pre-wrap">
            It's been a long while, but it's finally ready! well, mostly..
        </p>
        <p>
            OGotcha v5 is a complete rewrite of OGotcha, it most notably brings "fuel in debris" support. There are also a ton of backend
            improvements that should help me keep OGotcha afforable for the foreseeable future.
        </p>
        <p>
            There will be bugs and missing translations. Please consider joining my new discord and report any bugs you find and if you can,
            help to finish the translations. The old version will be available for a short while on <a href="https://ogotcha-legacy.oplanet.eu/">ogotcha-legacy.oplanet.eu</a>.
            But please note that the links generated in legacy will not continue to work in the future!!
        </p>
        <p>
            <i>Shameless plug</i> I've added a patreon link on the top of the page, please consider supporting me there, it'll help to keep things running!
        </p>
        <p>
            Happy hunting!
        </p>
        <p>
            <span class="signature">❤️ n00b</span>
        </p>
        <div class="uv-apps-popup-btn" @click="seen = false">Close</div>
    </ModalComponent>
</template>

<style scoped>
a {
    color: #fff;
}
a:visited {
    color: #ddd;
}

.uv-apps-popup-btn {
    border: 3px solid #506784;
    border-radius: 3px;
    padding: 7px 15px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
    transition: .25s;
}

.uv-apps-popup-btn:hover {
    background-color: #506784;
}

.signature {
    font-size: 16px;
    font-weight: 400;
}

</style>