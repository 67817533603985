// bbob bbcode to html stuff
import { createPreset } from '@bbob/preset'
import bbobHTML from '@bbob/html'

const preset = createPreset({
    color: (node: any) => ({
        tag: 'span',
        attrs: { style: `color: ${Object.values(node.attrs)[0]};`},
        content: node.content
    }),
    align: (node: any) => ({
        tag: 'div',
        attrs: { style: `text-align: ${Object.values(node.attrs)[0]};`},
        content: node.content
    }),
    size: (node: any) => ({
        tag: 'span',
        attrs: { style: `font-size: ${Object.values(node.attrs)[0]}px` },
        content: node.content
    }),
    url: (node: any) => ({
        tag: 'a',
        attrs: { href: Object.values(node.attrs)[0]},
        content: node.content
    })
})

export function toHTML(bbcode: string): string {
    return bbobHTML(bbcode, preset())
}