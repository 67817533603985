<script setup lang="ts">
</script>

<template>
    <footer>
        <nav id="nav-bottom">
            <ul class="clearfix">
                <li><a href="en/contributors">Contributors</a></li>
                <li><a href="https://board.en.ogame.gameforge.com/index.php?thread/765464-ogotcha-simple-cr-converter-for-ogame-v6/" class="help-feedback" target="_blank" data-ga-event="{&quot;eventType&quot;:&quot;button&quot;,&quot;label&quot;:&quot;help feedback&quot;}">Support</a></li>
                <li><a href="https://board.en.ogame.gameforge.com/index.php?thread/765464-ogotcha-simple-cr-converter-for-ogame-v6/" class="help-translate" target="_blank" data-ga-event="{&quot;eventType&quot;:&quot;button&quot;,&quot;label&quot;:&quot;help translate&quot;}">Translate</a></li>
            </ul>
        </nav>
        <div id="copy">
            © 2015 - 2023 <a href="http://klaas.cc/" target="_blank">Klaas Van Parys</a> | © 2023 - <u>n00b</u>s | For <a href="http://en.ogame.gameforge.com/" target="_blank"><img src="@/assets/ogame-logo.png"></a>
        </div>
        <div style="margin-top: 20px;">
        </div>
    </footer>
</template>