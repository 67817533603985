<script setup lang="ts">
import TitleComponent from '@/components/common/TitleComponent.vue'
import MenuComponent from '@/components/common/MenuComponent.vue'

import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Head } from '@unhead/vue/components'

import { toHTML } from '@/composables/bbcode'
import IconFlag from '@/components/icons/IconFlag.vue'

const route = useRoute()

type Result = {
    result: string
    meta: {
        community: string
        server: number
        total: {
            attacker: number
            defender: number
        },
        names: {
            attacker: string[]
            defender: string[]
        }
    }
    report: string
}

let result = ref<Result>()
const html = ref("")

async function loadReport() {
    console.log(route.params.id)

    fetch(`${import.meta.env.VITE_OGOTCHA_API}/v1/report/${route.params.id}`)
        .then(result => result.json())
        .then(data => {
            result.value = data
            html.value = toHTML(data.report)
        })
}

function color(n: number): { positive: boolean, negative: boolean } {
    return {
        'positive': (n > 0),
        'negative': (n < 0)
    }
}

onMounted(loadReport)
</script>

<template>
    <Head>
        <meta property="og:title" content="OGotcha - CR Converter" />
        <meta property="og:site_name" content="OGotcha CR Converter" />
        <meta property="og:url" :content="'https://ogotcha.oplanet.eu'+route.fullPath" />
        <meta property="og:image" :content="'https://ogotcha.oplanet.eu/images/report/'+route.params['id']+'.png'" />
    </Head>
    <div id="page-report">
        <TitleComponent />
        <MenuComponent />
    </div>
	<div id="report-header">
		<h2 class="subtitle">{{ $t('report.show.title') }}</h2>
		<div id="report-players">
			<h3 class="clearfix">{{ $t('report.show.players') }} <span>{{ $t('reports.community') }} <IconFlag :code="result?.meta.community || '00'" /></span></h3>
			<div class="clearfix">
				<div id="report-attackers" class="report-players-block">
					<h4>{{ $t('reports.header.attacker.title') }}</h4>
					<div class="report-players-summary" v-bind:class="color(result?.meta.total.attacker || 0)">{{ new Intl.NumberFormat('nl').format(result?.meta.total.attacker || 0) }}</div>
					<ul>
						<li v-for="name in result?.meta.names.attacker">{{ name }}</li>
					</ul>
				</div>
				<div id="report-defenders" class="report-players-block">
					<h4>{{ $t('reports.header.defender.title') }}</h4>
					<div class="report-players-summary" v-bind:class="color(result?.meta.total.defender || 0)">{{ new Intl.NumberFormat('nl').format(result?.meta.total.defender || 0) }}</div>
					<ul>
						<li v-for="name in result?.meta.names.defender">{{ name }}</li>
					</ul>
				</div>
				<div id="report-versus" class="subtitle">{{ $t('report.show.versus') }}</div>
			</div>
		</div>
	</div>
	<div id="report-content-wrapper">
		<div id="report-content" style="white-space: pre-line" v-html="html"></div>
	</div>
</template>

<style scoped>
.negative { color: rgb(255, 100, 100) }
.positive { color: rgb(63, 255, 63) }
</style>