<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

// Components
import OptionsBox from '../common/OptionsBox.vue'
import OptionsKeyList from './OptionsKeyList.vue'
import OptionsColors from './OptionsColors.vue'
import Store from '@/lib/ogotcha/storage'

import draggable from 'vuedraggable'
import { PartyMembers, type Options } from '@/lib/ogotcha'
import defaultSkins from '@/lib/ogotcha/skins'
import i18next from 'i18next'

const emit = defineEmits(['update:modelValue', 'showOptions', 'showLegend'])
const props = defineProps<{ modelValue: Options }>()

const saveOptions = computed(Store.stored('save', false))
const showOptions = computed(Store.stored('default', false))
const options = computed({
    get(): Options {
        return props.modelValue
    },
    set(value: Options) {
        storeOptions()
        emit("update:modelValue", value);
    }
})

const booleanOptions: (keyof Options)[] = [
    "advanced",
    "hidetime",
    "merge",
    "alliance",
    "honour",
    "moonchance",
    "combattech"
]

function storeOptions() {
    if (saveOptions.value) {
        localStorage.setItem(Store.prefix+'saved', JSON.stringify(options.value))
    }
}

const skin = ref("-")
const skins = ref(defaultSkins)

function setDefaultSkin() {
    const languageSkin = i18next.language + "- default"
    const newSkin = (languageSkin in skins.value) ? languageSkin : "-";

    if (skin.value == newSkin || saveOptions.value) {
        return
    }

    skin.value = newSkin
}

onMounted(() => {
    setDefaultSkin()
    if (showOptions.value) {
        emit('showOptions')
    }
})

watch(skin, () => { options.value = skins.value[skin.value] })
watch(options, storeOptions, {deep: true})
watch(ref(i18next.language), setDefaultSkin)

</script>

<template>
    <div id="cr-options">
        <div id="cr-options-top-container" class="clearfix">
            <OptionsBox id="cr-gotdebris" :title="$t('home.opt.gotdebris.auto')" class="cr-options-box">
                <select id="cr-gotdebris-select" v-model="options.gotdebris">
                    <option 
                        v-for="(elem, index) in ['attacker', 'defender', 'home.opt.gotdebris.none', 'home.opt.gotdebris.key']"
                        :value="index"
                        v-bind:key="index"
                    >{{ $t(elem) }}</option>
                </select>
            </OptionsBox>
            <OptionsBox id="cr-report-skin" :title="$t('home.opt.report.skin')" class="cr-options-box">
                <select id="cr-skin-select" v-model="skin">
                    <option 
                        v-for="(_, index) in skins"
                        :value="index"
                        v-bind:key="index"
                    >{{ index }}</option>
                </select>
            </OptionsBox>
            <OptionsBox id="cr-report-title" :title="$t('home.opt.report.title.custom')" class="cr-options-box">
                <template v-slot:title="props">
                    <label class="clearfix">
                        {{ props.title }}
                        <a href="#" @click="$emit('showLegend')" id="cr-report-title-legend">* {{ $t('home.opt.report.legend') }}</a>
                    </label>
                </template>
                <input type="text" id="cr-report-title-field" v-model="options.title">
            </OptionsBox>
        </div>
        <div id="cr-options-container" class="cr-options-container clearfix">
            <OptionsBox id="cr-options-checkboxes" class="cr-options-box cr-options-checkboxes" :title="$t('home.options')">
                <label v-for="key in booleanOptions" v-bind:key="key">
                    {{ $t('home.opt.'+key) }}
                    <input type="checkbox" v-model="options[key]">
                </label>
            </OptionsBox>
            <OptionsBox 
                v-for="p in PartyMembers" v-bind:key="p"
                :title="$t('home.opt.'+p)"
                class="cr-options-box cr-options-player"
            >
                <OptionsKeyList 
                    v-model="options.harvest[p]" 
                    :title="$t('home.opt.harvest')"
                    placeholder="Harvest report key"
                />
                <OptionsKeyList
                    v-model="options.deuterium[p]"
                    :title="$t('home.opt.deuterium')"
                    placeholder="Deuterium"
                />
            </OptionsBox>
            <OptionsBox :title="$t('home.opt.attacker')+' '+$t('home.opt.extra')" class="cr-options-box cr-options-player">
                <OptionsKeyList
                    v-model="options.raids"
                    :title="$t('home.opt.raids')"
                    placeholder="Combat report key"
                />
                <OptionsKeyList
                    v-model="options.missile"
                    :title="$t('home.opt.missile')"
                    placeholder="Missile report key"
                />
            </OptionsBox>
        </div>
        <div id="cr-options-adv-container" class="cr-options-container clearfix">
            <OptionsBox :title="$t('home.opt.adv.color.title')" id="cr-adv-colors" class="cr-options-adv-box">
                <OptionsColors v-model="options.colors" />
                <div id="cr-options-actions">
                    <label id="cr-options-show" v-bind:class="{ 'cr-btn-active': showOptions }">
                        <input type="checkbox" id="cr-show-by-default" v-model="showOptions" />
                        {{ $t('home.showoptions') }}
                    </label>

                    <label id="cr-options-save" v-bind:class="{ 'cr-btn-active': saveOptions }">
                        <input type="checkbox" id="cr-save-options" v-model="saveOptions" />
                        {{ $t('home.saveoptions') }}
                    </label>
                </div>
            </OptionsBox>
            <OptionsBox :title="$t('home.opt.adv.layout.title')" id="cr-adv-layout" class="cr-options-adv-box">
                <draggable v-model="options.layout" tag="ul" item-key="id">
                    <template #item="{element, index}">
                        <li :key="element.id">
                            <label>
                                <input type="checkbox" v-model="options.layout[index].show">
                                {{ $t('home.opt.adv.layout.'+element.id) }}
                            </label>
                        </li>
                    </template>
                </draggable>
            </OptionsBox>
        </div>
    </div>
</template>

<style scoped>
h4 {
    margin-bottom: 10px !important;
}
</style>