<script setup lang="ts">
import type { ColorOptions } from '@/lib/ogotcha/options';
import { computed, defineComponent } from 'vue';

const emit = defineEmits(["update:modelValue"])
const props = defineProps<{ modelValue: ColorOptions }>()

type ColorMapping = {
    key: keyof ColorOptions
    lang: string
}[]

const mapping: ColorMapping = [
    { key: "attacker", lang: "home.opt.adv.color.attacker.title" },
    { key: "attackerFleet", lang: "home.opt.adv.color.attacker.fleet" },
    { key: "defender", lang: "home.opt.adv.color.defender.title" },
    { key: "defenderFleet", lang: "home.opt.adv.color.defender.fleet" },
    { key: "stats", lang: "home.opt.adv.color.numbers.stats" },
    { key: "summary", lang: "home.opt.adv.color.numbers.summary" },
    { key: "negative", lang: "home.opt.adv.color.numbers.negative" },
]

const colors = computed({
    get: () => props.modelValue,
    set: (val) => emit('update:modelValue', val)
})

function text(rgb: string) {
    if (rgb.length == 4) {
        rgb = '#'+rgb.charAt(1)+rgb.charAt(1)+rgb.charAt(2)+rgb.charAt(2)+rgb.charAt(3)+rgb.charAt(3)
        console.log('rewritten', rgb)
    } else if (rgb.length < 7) {
        return 'light';
    }

    const dec = parseInt(rgb.substring(1), 16)
    const srgb = [((dec & (255<<16))>>16)/255., ((dec & (255<<8))>>8)/255., (dec & 255)/255.];
    const multipliers = [0.2126, 0.7152, 0.0722];

    // calculate relative luminance
    // see also https://www.w3.org/TR/WCAG20/#relativeluminancedef
    const luminance = srgb.map((v) => {
        return (v <= 0.03928) ? v/12.92 : Math.pow((v+0.055)/1.055, 2.4)
    }).
    map((v, i) => { return v*multipliers[i]}).
    reduce((v, c) => { return c+v }, 0)

    if (luminance > Math.sqrt(1.05 * 0.05) - 0.05) {
        return 'light'
    } else {
        return 'dark'
    }
}
</script>

<template>
    <ul class="clearfix">
        <li v-for="(map, i) in mapping" v-bind:key="i">
            <label>
                {{ $t(map.lang) }}
                <input
                    type="text"
                    v-model="colors[map.key]"
                    :style="'background-color: '+colors[map.key]"
                    :class="text(colors[map.key])"
                />
            </label>
        </li>
    </ul>
</template>

<style scoped>
.light {
    color: black;
}
.dark {
    color: white;
}
</style>

<script lang="ts">
export default defineComponent({
    methods: {
        text(rgb: string) {
            if (rgb.length == 4) {
                rgb = '#'+rgb.charAt(1)+rgb.charAt(1)+rgb.charAt(2)+rgb.charAt(2)+rgb.charAt(3)+rgb.charAt(3)
                console.log('rewritten', rgb)
            } else if (rgb.length < 7) {
                return 'light';
            }

            const dec = parseInt(rgb.substring(1), 16)
            const srgb = [((dec & (255<<16))>>16)/255., ((dec & (255<<8))>>8)/255., (dec & 255)/255.];
            const multipliers = [0.2126, 0.7152, 0.0722];

            // calculate relative luminance
            // see also https://www.w3.org/TR/WCAG20/#relativeluminancedef
            const luminance = srgb.map((v) => {
                return (v <= 0.03928) ? v/12.92 : Math.pow((v+0.055)/1.055, 2.4)
            }).
            map((v, i) => { return v*multipliers[i]}).
            reduce((v, c) => { return c+v }, 0)

            if (luminance > Math.sqrt(1.05 * 0.05) - 0.05) {
                return 'light'
            } else {
                return 'dark'
            }
        }
    }
})
</script>