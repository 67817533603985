<script setup lang="ts">
import { defineComponent } from 'vue';
import IconPatreon from '../icons/IconPatreon.vue';
import IconDiscord from '../icons/IconDiscord.vue';

</script>

<template>
    <header>
        <ul class="app-bar">
            <li class="logo">
                <img src="@/assets/moon.png">
            </li>
            <li>
                <span class="desktop">oPlanet</span>
                <span class="mobile">Uv</span>
                <br>
                Apps
            </li>
            <li class="center">
                <ul>
                    <li>
                        <RouterView name="LocalePicker" />
                    </li>
                    <li>
                        <a class="social-share btn-social-share discord" href="https://discord.gg/apPAWAJMZc" target="_blank"><IconDiscord /></a>
                    </li>
                    <li>
                        <a class="social-share btn-social-share patreon" href="https://www.patreon.com/bePatron?u=17853644" target="_blank"><IconPatreon /></a>
                    </li>
                </ul>
            </li>
            <li class="link desktop">
                <a href="https://trashsim.oplanet.eu/" target="_blank">TrashSim</a>
            </li>
            <li class="link desktop">
                <a href="https://ogotcha.oplanet.eu/" target="_blank">OGotcha</a>
            </li>
        </ul>
    </header>
</template>

<script lang="ts">
export default defineComponent({})
</script>

<style scoped>
header {
    background:#1E2B39;
    color:      #fff;
    box-shadow: 0 2px 20px rgba(0,0,0,.4);
    font-size:  16px;
}

.app-bar {
    display: flex;
    max-width: 900px;
    margin:         0 auto;
    list-style-type: none;
    padding: 0;
    align-items: center;
}

.app-bar ul {
    list-style-type: none;
    padding: 0;
    align-items: center;
}

.app-bar ul li {
    margin: 0 5px;
}

.app-bar li {
    float: left;
}

.app-bar li.logo {
    height: 60px;
    padding: 0;
    margin: 0 10px 0 0;
}

.app-bar li.center {
    float:none;
    margin: 0 auto;
    align-items: center;
}

.app-bar li.link {
    float: right;
    border-width:0 0 0 1px;
    border-color:rgba(80,103,132,.3);
    border-style:solid
}

.app-bar li.link:hover {
    background-color:rgba(0,0,0,.4) !important
}

.app-bar li.link:last-child {
    border-width:0 1px;
}

.app-bar li.link a {
    color:#fff;
    text-decoration:none;
    height:60px;
    line-height:60px;
    padding:0 10px;
    display:block;
    border-bottom:3px solid rgba(80,103,132,.5)
}

.logo img {
    height:     60px;
}

.social-share {
    border-radius: 2px;
    height: 39px;
    width: 39px;
    font-size: 1em;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: 3px 4px;
}

.social-share-btn {
    display: inline-flex;
    height: 25;
}

.social-share.discord {
    background-color: #5865F2;
}

.social-share.patreon {
    background-color: #f1465a;
}

.social-share.discord > svg {
    margin: 4px;
    width: 30px;
    color: white;
}

.mobile {
    display:        none;
}

@media (max-width:43.750em) {
    .mobile {
        display: initial;
    }

    .desktop {
        display: none;
    }
}
</style>