import type { Options, Parties, ColorOptions, KeyList, CostList } from './options'


const PartyMembers: (keyof Parties<any>)[] = ["attacker", "defender"]

export {
    type Options,
    type Parties,
    type ColorOptions,
    type KeyList,
    type CostList,
    PartyMembers
}