<template>
    <div id="cr-howto">
        <h3 class="subtitle">{{ $t('home.howto') }}</h3>
        <ol class="clearfix">
            <li>
                <img src="@/assets/cr-howto-step1.png" />
                <div class="cr-howto-pin" id="step-1">1</div>
                <div class="cr-howto-content">
                    <p v-html="$t('home.step1.paragraph1')"></p>
                    <p v-html="$t('home.step1.paragraph2')"></p>
                    <p v-html="$t('home.step1.paragraph3')"></p>
                </div>
            </li>
            <li>
                <img src="@/assets/cr-howto-step2.png" />
                <div class="cr-howto-pin" id="step-2">2</div>
                <div class="cr-howto-content">
                    <p v-html="$t('home.step2.paragraph1')"></p>
                    <p v-html="$t('home.step2.paragraph2')"></p>
                    <p v-html="$t('home.step2.paragraph3')"></p>
                </div>
            </li>
            <li>
                <img src="@/assets/cr-howto-step3.png" />
                <div class="cr-howto-pin" id="step-3">1</div>
                <div class="cr-howto-content">
                    <p v-html="$t('home.step3.paragraph1')"></p>
                    <p v-html="$t('home.step3.paragraph2')"></p>
                    <p v-html="$t('home.step3.paragraph3')"></p>
                </div>
            </li>
        </ol>
    </div>
</template>