export interface gettersetter<T> {
    get(): T
    set(value: T): void
}

class Store {
    static prefix: string = "OGotcha_cr_options_";

    static Boolean(key: string, fallback: boolean): gettersetter<boolean> {
        return {
            get(): boolean {
                const stored = localStorage.getItem(Store.prefix+key)
                if (stored) {
                    return JSON.parse(stored)
                }
                return fallback
            },
            set(value: boolean) {
                localStorage.setItem(Store.prefix+key, JSON.stringify(value))
            }
        }
    }

    static stored<T>(key: string, fallback: T): gettersetter<T> {
        return {
            get(): T {
                const stored = localStorage.getItem(Store.prefix+key)
                if (stored) {
                    return JSON.parse(stored)
                }
                return fallback
            },
            set(value: T) {
                localStorage.setItem(Store.prefix+key, JSON.stringify(value))
            }
        }
    }
}

export default Store