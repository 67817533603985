<script setup lang="ts">
const legend = [
    "td", "att", "def",
    "tda", "tdd",
    "tpa", "tpd",
    "hmc", "time",
    "tdr", "tdra", "tdrd",
    "tdo", "tdoa", "tdod"
]
</script>

<template>
    <div id="report-title-legend">
        <h2>{{ $t('home.opt.report.custom.title') }}</h2>
        <ul>
            <li v-for="key of legend" :key="key">
                <span>{{ '\{\{'+key+'\}\}' }}</span>
                {{ $t('home.opt.report.title.'+key) }}
            </li>
        </ul>
    </div>
</template>