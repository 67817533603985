<script setup lang="ts">
import type { KeyList, CostList } from '@/lib/ogotcha/options';
import { computed } from 'vue';

const emit = defineEmits(["update:modelValue"])
const props = defineProps<{
    modelValue: KeyList | CostList
    title: string
    placeholder?: string
}>()

const keys = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value)
})
</script>

<template>
    <div>
        <label>{{ title }}</label>
        <input
            v-for="(_,i) of keys" 
            :key="i"
            type="text"
            v-model="keys[i]"
            :placeholder="placeholder"
        />
        <a href='#' class="cr-option-add" @click.prevent="keys.push(null)">{{ $t('home.opt.more') }}...</a>
    </div>
</template>