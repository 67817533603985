interface Options {
    gotdebris: number
    title: string
    language?: string

    // boolean options
    advanced: boolean
    hidetime: boolean
    merge: boolean
    alliance: boolean
    honour: boolean
    moonchance: boolean
    combattech: boolean

    // api keys
    missile: KeyList
    raids: KeyList

    harvest: Parties<KeyList>
    deuterium: Parties<CostList>

    colors: ColorOptions
    layout: LayoutItem[]
}

interface ColorOptions {
    attacker: string
    attackerFleet: string
    defender: string
    defenderFleet: string
    stats: string
    summary: string
    negative: string
}

interface LayoutItem {
    id: string
    show: boolean
}

type Parties<T> = {
    attacker: T
    defender: T
}

type KeyList = (string | null)[]
type CostList = (number | null)[]

function DefaultOptions(): Options {
    return {
        gotdebris: 0,
        title: "[TOT: {td}] {att} vs. {def} (A: {tda}, D: {tdd})",
        language: "en",
        advanced: true,
        hidetime: true,
        merge: true,
        alliance: true,
        honour: false,
        moonchance: true,
        combattech: false,

        missile:  [null],
        raids:    [null],

        harvest:   { attacker: [null], defender: [null] },
        deuterium: { attacker: [null], defender: [null] },

        colors: {
            attacker: '#ff0000',
            attackerFleet: '#fc850c',
            defender: '#008000',
            defenderFleet: '#1c84be',
            stats: '#3183e7',
            summary: '#fc850c',
            negative: '#ff0000'
        },

        layout: [
            { id: "combatstart", show: true },
            { id: "combatend", show: true },
            { id: "stats", show: true },
            { id: "moon", show: true },
            { id: "harvest", show: true },
            { id: "missiles", show: true },
            { id: "summary", show: true },
        ]
    }
}

function override<T extends Object>(base: T, overrides: T, keys: (keyof T)[]) {
    keys.forEach(key => {
        if (key in overrides) {
            console.log('override', key, overrides[key])
            base[key] = overrides[key]
        }
    })
}

function OverrideOptions(base: Options, json: string) {
    const options = JSON.parse(atob(json))

    override(base, options, ['gotdebris', 'title', 'language', 'advanced', 'hidetime', 'merge', 'alliance', 'honour', 'moonchance', 'combattech', 'missile', 'raids', 'layout'])

    if ('colors' in options) {
        override(base.colors, options.colors, ['attacker', 'attackerFleet', 'defender', 'defenderFleet', 'stats', 'summary', 'negative'])
    }

    if ('harvest' in options) {
        override(base.harvest, options.harvest, ['attacker', 'defender'])
    }

    if ('deuterium' in options) {
        override(base.deuterium, options.deuterium, ['attacker', 'defender'])
    }

    console.log('result', base)
}

export {
    type Options,
    type Parties,
    type ColorOptions,
    type KeyList,
    type CostList,
    DefaultOptions,
    OverrideOptions
}