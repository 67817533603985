<script setup lang="ts">
import { defineComponent } from 'vue';

</script>

<template>
    <div id="share-public">
        <h4>{{ $t('report.share.question') }}</h4>
        <a @click="$emit('callback', true)" id="share-public-yes">{{ $t('report.share.yes') }}</a>
        <a @click="$emit('callback', false)" id="share-public-no">{{ $t('report.share.no') }}</a>
    </div>
</template>

<script lang="ts">
export default defineComponent({

})
</script>