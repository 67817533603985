<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { DefaultOptions, OverrideOptions, type Options } from '@/lib/ogotcha/options'
import Store from '@/lib/ogotcha/storage'

import MenuComponent from '@/components/common/MenuComponent.vue'
import TitleComponent from '@/components/common/TitleComponent.vue'
import ModalComponent from '@/components/common/ModalComponent.vue'

import OptionsComponent from '@/components/home/OptionsComponent.vue'
import HowtoComponent from '@/components/home/HowtoComponent.vue'
import ResultComponent from '@/components/home/ResultComponent.vue'
import LegendComponent from '@/components/home/LegendComponent.vue'
import { useRoute } from 'vue-router'
import i18next from 'i18next'

export declare interface Data {
    api: string | null
    showOptions: boolean
    showReport: boolean
    showLegend: boolean
    options: Options
    report: Report
}

export type Report = {
    id?: string
    title?: string
    report?: string
}

const route = useRoute()
const saveOptions = computed(Store.stored('save', false))

function loadOptions() {
    const saved = localStorage.getItem(Store.prefix+'saved')
    if (saved == null) {
        return
    }
    try {
        let savedOptions: Options = JSON.parse(saved)

        savedOptions.harvest.attacker = [null]
        savedOptions.harvest.defender = [null]
        savedOptions.deuterium.attacker = [null]
        savedOptions.deuterium.defender = [null]
        savedOptions.raids = [null]
        savedOptions.missile = [null]

        options.value = savedOptions
    } catch (err :any) {
        console.error('bad options data', err.message, saved)
    }
}

const api = ref<string | null>(null)
const options = ref<Options>(DefaultOptions())
const showReport = ref(false)
const report = ref<Report>({})
const showLegend = ref(false)
const showOptions = ref(false)

onMounted(() => {
    api.value = route.query["CR_KEY"]?.toString() || null

    if (saveOptions.value) {
        loadOptions()
    }

    if (route.query["options"]) {
        OverrideOptions(options.value, route.query["options"].toString())
        console.log('options', options.value)
    }
})

function setDebris(value: number) {
    options.value.gotdebris = value
}

function convert() {
    if (api.value) {
        options.value.language = i18next.language
        fetch(`${import.meta.env.VITE_OGOTCHA_API}/v1/convert/${api.value}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(options.value)
        })
        .then(result => result.json())
        .then(result => {
            report.value = result
            showReport.value = true
        })
    }
}

</script>

<template>
    <ModalComponent v-model="showReport">
        <ResultComponent v-model="report" />
    </ModalComponent>
    <ModalComponent v-model="showLegend">
        <LegendComponent />
    </ModalComponent>
    <section id="info"></section>

    <div id="home-page">
        <TitleComponent />
        <MenuComponent />

        <div id="cr-hint" class="subtitle clearfix">
            <span>⇃</span>
            <span v-html="$t('home.hint')"></span>
        </div>
        <div id="cr-form" class="clearfix" v-bind:class="{ 'cr-opt-active': showOptions }">
            <form @submit.prevent="convert">
                <div id="input-wrapper">
                    <input type="text" id="cr-key-input" v-model="api" placeholder="Ctrl + V">
                </div>
                <button id="cr-convert">
                    &nbsp;{{ $t('home.convert') }}
                    <div id="cr-gotdebris-auto">
                        <h4>{{ $t('home.opt.gotdebris.auto') }}</h4>
                        <ul>
                            <li 
                                v-for="(elem, index) in ['attacker', 'defender', 'home.opt.gotdebris.none', 'home.opt.gotdebris.key']"
                                v-bind:key="index"
                                @click="setDebris(index)"
                            >{{ $t(elem) }}</li>
                        </ul>
                    </div>
                </button>
                <button id="cr-options-btn" @click.prevent="showOptions = !showOptions">≡ {{ $t('home.options') }}</button>
                <OptionsComponent
                    v-model="options"
                    @showOptions="showOptions = true"
                    @showLegend="showLegend = true"
                />
            </form>
        </div>
    </div>

    <HowtoComponent />
</template>

<style scoped>
</style>