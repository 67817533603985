<script setup lang="ts">
</script>

<template>
    <div class="menu clearfix">
    	<ul>
    		<li><a :href="'/'+$i18next.language">↢ {{ $t('menu.homepage') }}</a></li>
    		<li><a :href="'/'+$i18next.language+'/reports'" >☱ {{ $t('menu.public') }}</a></li>
    		<li><a href="https://trashsim.oplanet.eu/" target="blank">⚔ TrashSim</a></li>
    	</ul>
    </div>
</template>

<style scoped>
.menu {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    width: 200px;
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu ul li {
    text-align: left;
    margin-bottom: 15px;
}

.menu ul li a {
    display: block;
    background: #1E2B39;
    border: 1px solid #3c4d60;
    color: #f1f1f1;
    box-shadow: 0 0 10px rgba(0,0,0,0.6);
    font-size: 1.7em;
    border-radius: 7px;
    text-decoration: none;
    padding: 3%;
}

.menu ul li a:hover {
    background: #3c4d60;
    border: 1px solid #1E2B39;
}
</style>