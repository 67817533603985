<script setup lang="ts">
import { computed, ref } from 'vue'
import PublishComponent from './PublishComponent.vue'

// bbob bbcode to html stuff
import { createPreset } from '@bbob/preset'
import bbobHTML from '@bbob/html'

export type Report = {
    id?: string
    title?: string
    report?: string
}

const props = defineProps<{ modelValue: Report }>()
const emit = defineEmits(["update:modelValue"])

const report = computed({
    get: () => props.modelValue,
    set: (val) => emit("update:modelValue", val)
})

const uraniaUrl = import.meta.env.VITE_URANIA_URL
const signaturePublic = ref(false)
const savePublic = ref(false)
const shared = ref(false)

async function publish(pub: boolean) {
    shared.value = true
    if (!pub) {
        return
    }

    fetch(`${import.meta.env.VITE_OGOTCHA_API}/v1/publish/${report.value.id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" }
    })
}

const preview = computed(() => {
    if (!report.value.report) {
        return ""
    }

    return bbobHTML(report.value.report, preset())
})

const preset = createPreset({
    color: (node: any) => ({
        tag: 'span',
        attrs: { style: `color: ${Object.values(node.attrs)[0]};`},
        content: node.content
    }),
    align: (node: any) => ({
        tag: 'div',
        attrs: { style: `text-align: ${Object.values(node.attrs)[0]};`},
        content: node.content
    }),
    size: (node: any) => ({
        tag: 'span',
        attrs: { style: `font-size: ${Object.values(node.attrs)[0]}px` },
        content: node.content
    }),
    url: (node: any) => ({
        tag: 'a',
        attrs: { href: Object.values(node.attrs)[0]},
        content: node.content
    })
})


</script>

<template>
    <div id="report-wrapper">
        <h2>{{ $t('report.output') }}</h2>
        <div id="report-title">
            <h3>{{ $t('report.title') }}</h3>
            <input type="text" v-model="report.title" />
        </div>
        <div id="report-bbcode">
            <h3>
                {{ $t('report.bbcode') }}
                <span class="hint">{{ $t('report.hint') }}</span>
            </h3>
            <textarea v-model="report.report"></textarea>
            <div id="report-social-share">
                <ul>
                    <li id="report-share-signature" @click="signaturePublic = !signaturePublic">
                        {{ $t('report.share.action.sig') }}
                        <PublishComponent v-show="signaturePublic" @callback="publish" />
                    </li>
                    <li id="report-share-url" @click="savePublic = !savePublic">
                        {{ $t('report.share.action.save') }}
                        <PublishComponent v-show="savePublic" @callback="publish" />
                    </li>
                    <li id="report-share-facebook">{{ $t('report.share.action.fb') }}</li>
                    <li id="report-share-twitter">{{ $t('report.share.action.tw') }}</li>
                </ul>
            </div>
        </div>
        <div v-show="shared" id="report-share">
            <h3>{{ $t('report.share.info.title') }}</h3>
            <div>
                <h4>{{ $t('report.share.info.url') }}</h4>
                <input type="text" :value="'https://ogotcha.oplanet.eu/en/report/'+report.id">
            </div>
            <div>
                <h4>{{ $t('report.share.info.img') }}</h4>
                <input type="text" :value="uraniaUrl+'/'+report.id+'.png'">
                <img v-if="report.id" :src="uraniaUrl+'/'+report.id+'.png'">
            </div>
        </div>
        <div id="report-preview">
            <h3>{{ $t('report.preview') }}</h3>
            <div id="report-preview-html" style="white-space: pre-line" v-html="preview"></div>
        </div>
    </div>
</template>