import type { App, Plugin } from "vue"
import i18next from 'i18next'
import I18NextVue from "i18next-vue"

import cs from './locales/cs.json'
import da from './locales/da.json'
import de from './locales/de.json'
import el from './locales/el.json'
import en from './locales/en.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import hr from './locales/hr.json'
import hu from './locales/hu.json'
import it from './locales/it.json'
import ko from './locales/ko.json'
import nl from './locales/nl.json'
import pl from './locales/pl.json'
import ptBR from './locales/pt-BR.json'
import pt from './locales/pt.json'
import ro from './locales/ro.json'
import ru from './locales/ru.json'
import sk from './locales/sk.json'
import sv from './locales/sv.json'
import tr from './locales/tr.json'
import zh from './locales/zh.json'

i18next.init({
    lng: 'en',
    fallbackLng: 'en',
    resources: {
        'cs': { translation: cs },
        'da': { translation: da },
        'de': { translation: de },
        'el': { translation: el },
        'en': { translation: en },
        'es': { translation: es },
        'fr': { translation: fr },
        'hr': { translation: hr },
        'hu': { translation: hu },
        'it': { translation: it },
        'ko': { translation: ko },
        'nl': { translation: nl },
        'pl': { translation: pl },
        'pt-BR': { translation: ptBR },
        'pt': { translation: pt },
        'ro': { translation: ro },
        'ru': { translation: ru },
        'sk': { translation: sk },
        'sv': { translation: sv },
        'tr': { translation: tr },
        'zh': { translation: zh },
    }
})

export const i18nextPlugin: Plugin = {
    install(app: App) {
        app.use(I18NextVue, { i18next })
    }
}